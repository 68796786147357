<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        outlined
        :loading="data.locked || loading"
        :color="hover ? 'grey lighten-3' : 'white'"
      >
      <template slot="progress">
        <v-progress-linear
          color="primary"
          height="5"
          indeterminate
          absolute
        ></v-progress-linear>
      </template>
      <template>
        <v-checkbox
          align="start"
          v-model="isSelected"
          @click="toggleSelection"
          style="margin: 8px; position: absolute; z-index: 2;"
        ></v-checkbox>
      </template>

        <v-img
          v-if="thumbUrl"
          @click.stop="handleClick"
          :src="thumbUrl"
          :class="data.locked ? '' : 'pointer'"
          class="white--text align-center img-bg"
          height="200px"
          align="center"
          justify="center"
          contain
        ></v-img>

        <v-img
          v-else
          @click.stop="handleClick"
          :class="data.locked ? '' : 'pointer'"
          class="white--text align-center img-bg"
          height="200px"
          align="center"
          justify="center"
          contain
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <div v-if="data.locked">
                <v-icon
                  :color="'defaultIconColor'"
                  size="70"
                >mdi-upload</v-icon>
                <p class="uploading">
                  Uploading<span class="dots">.</span><span class="dots">.</span><span class="dots">.</span>
                </p>
              </div>
              <v-icon
                v-else
                :color="icon.color || 'defaultIconColor'"
                size="150"
              >{{ icon.icon }}</v-icon>
            </v-row>
          </template>

          <v-icon
            v-if="isPlayableType() && !data.locked"
            x-large
          >mdi-play-circle</v-icon>

        </v-img>

        <v-card-actions class="card-actions">

          <v-icon
            :color="icon.color || 'defaultIconColor'"
          >{{ icon.icon }}</v-icon>

          <v-tooltip open-delay="1000" bottom>
            <template v-slot:activator="{ on, attrs }">

              <v-card-subtitle
                v-bind="attrs"
                v-on="on"
                class="custom-subtitle"
              >{{ data.name }}</v-card-subtitle>

            </template>
            <span>{{ data.name }}</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-menu
            v-if="!disableActionsMenu"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <ContentActionsPopup
              :data="data"
              :filter-type="fileNodeType()"
              @preview="openPreviewModal"
              @move-success="$emit('move-success')"
            />
          </v-menu>

        </v-card-actions>

        <PreviewModal ref="previewModal" :node="data" :file-icon="icon" />
      </v-card>
    </template>
  </v-hover>
</template>

<style scoped>
  .pointer {
    cursor: pointer;
  }

  .img-bg {
    background-color: #DADCE0;
  }

  .preview-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }

  .preview {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
  }

  .card-actions {
    padding: 1px 8px 1px 8px;
  }

  .custom-subtitle {
    font-weight: 500;
    color: rgb(60, 64, 67) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 16px 12px !important;
  }

  .v-card {
    border-color: #DADCE0 !important;
  }

  .uploading {
    font-size: 16px;
    font-weight: bold;
    color: var(--v-defaultIconColor-base);
    margin: 0;
  }

  .dots {
    display: inline-block;
    animation: bounce 1.5s infinite ease-in-out;
    padding: 0 1px;
  }

  .dots:nth-child(2) {
    animation-delay: 0.2s;
  }

  .dots:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-6px);
    }
  }
</style>

<script>
import { mapState, mapActions } from 'vuex'
import router from '@/router'

import IconsByMimetype from '@/utils/iconsByMimetype.js'
import UpdateBrowserTab from '@/utils/updateBrowserTab'
import NodeTypes from '@/store/content/actions/node-types.js'

import PreviewModal from '@/components/content/preview/PreviewModal'

import ContentActions from '@/store/content/actions/actions-types'

export default {
  name: 'FileCard',

  components: {
    PreviewModal
  },

  beforeCreate: function () {
    this.$options.components.ContentActionsPopup = require('@/components/content/ContentActionsPopup.vue').default
  },

  props: {
    data: {
      type: Object
    },

    disableActionsMenu: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      preview: false,
      isSelected: false,
      clickCount: 0,
      loading: false,
      loadedActions: false,
      actions: [],
    }
  },

  computed: {
    ...mapState({
      mappedEditActions: state => state.content.actions.editActionByExtension,
    }),

    icon() {
      let icon = IconsByMimetype.getIconByMimetype(this.data.extension, this.data.mime)

      if (this.data.style_color) {
        icon.color = this.data.style_color
      }

      return icon
    },

    thumbUrl() {
      if (Array.isArray(this.data.renditions)) {
        return this.data.renditions[0]
      }

      if (typeof this.data.renditions === 'object') {
        return Object.values(this.data.renditions)[0]
      }

      return false;
    },
  },

  methods: {
    ...mapActions('content/actions', [
      ContentActions.GET_NODE_ACTIONS,
    ]),

    isPlayableType() {
      const mimetype = this.data.mime ?? ""

      if (mimetype.search('video|audio|mxf') >= 0) {
        return true
      }

      return false
    },

    fileNodeType() {
      return NodeTypes.NODE_FILE
    },

    openPreviewModal() {
      if(!this.data.locked)
        this.$refs.previewModal.openModal()
    },

    toggleSelection() {
      this.$emit('toggle-selection', this.data.node_id);
    },

    markSelectionAs(selected) {
      this.isSelected = selected
    },

    handleClick(event) {
      this.clickCount++

      setTimeout(() => {
        if (this.clickCount === 1) {
          this.openPreviewModal(event)
        } else if (this.clickCount === 2) {
          this.edit(event)
        }

        this.clickCount = 0
      }, 250)
    },

    edit() {
      if (this.disableActionsMenu) {
        return
      }

      if (this.loadedActions) {
        this.genericEdit()

        return
      }

      this.loading = true

      this[ContentActions.GET_NODE_ACTIONS](this.data.node_id)
        .then((result) => {
          this.actions = [...result]
          this.loadedActions = true

          this.genericEdit()
        })
        .finally(() => {
          this.loading = false
        })
    },

    genericEdit() {
      const editAction = this.mappedEditActions.find(action => action.extensions.includes(this.data.extension))

      const action = this.actions.find(action => action.action_key === editAction?.actionKey)

      if (action) {
        this[editAction.callMethod]()
      }
    },

    editGDocAction() {
      let routeData = router.resolve({name: 'EditGDoc', params: { appId: this.$route.params.appId, nodeId: this.data.node_id }, query: { from: this.$route.fullPath } });
      let newWindow = window.open(routeData.href, '_blank');
      let icon = IconsByMimetype.getIconByMimetype(this.data.extension, this.data.mime).icon

      newWindow.addEventListener('load', () => {
        UpdateBrowserTab.update(newWindow, this.data.name, icon)
      })
    },

    editSyntheticMediaAction() {
      let routeData = router.resolve({name: 'EditSyntheticMedia',  params: { appId: this.$route.params.appId, nodeId: this.data.node_id }});
      let newWindow = window.open(routeData.href, '_blank')

      newWindow.addEventListener('load', () => {
        UpdateBrowserTab.update(newWindow, this.data.name, 'mdi-star-four-points-box')
      });
    },

    editJstAction() {
      let routeData = router.resolve({name: 'EditJst', params: { appId: this.$route.params.appId, nodeId: this.data.node_id }, query: { from: this.$route.fullPath } });
      let newWindow = window.open(routeData.href, '_blank')

      newWindow.addEventListener('load', () => {
        UpdateBrowserTab.update(newWindow, this.data.name)
      });
    },
  },
}

</script>

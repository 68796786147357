<template>
  <DefaultModal
    :title="$t('set_custom_icon')"
    :loading="loading"
    :card-cover="cardCover"
    @submit="saveCustomization"
    @dropped="onDrop"
    @draggedover="dragOver($event)"
    @draggedenter="dragEnter($event)"
    @draggedleave="dragLeave()"
    @draggedend="dragEnd()"
    ref="modal"
    eager
  >
    <template v-slot:content>
      <p class="text-h6 black--text">{{ $t('customize_thumbnail') }}</p>

      <UploadField
        @change.native="loadNewImage($event.target.files)"
        @clearInput="clearNewImage"
        :class="{ 'drag-over': dropFileDragOver }"
        :errorMessage="errorMessage"
        :multiple="false"
        :accept="'image/*'"
        ref="uploadFileField"
      />

      <p class="text-h6 black--text">{{ $t('set_icon_color') }}</p>

      <v-card
        class="d-flex justify-start my-5 ml-5"
        flat
        tile
      >
        <v-item-group v-model="selectedColor">
          <v-row>
            <v-item
              v-for="color in colors"
              :key="color.value"
              v-slot="{ active, toggle }"
              :value="color.value"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    @click.native="toggle"
                    v-bind="attrs"
                    v-on="on"
                    :style="{
                      backgroundColor: color.value,
                      border: '2px solid',
                      borderColor: active ? color.border : 'white'
                    }"
                    class="mr-2 rounded-circle color-card"
                    width="30"
                    height="30"
                  ></v-card>
                </template>
                <span>{{ $t(color.text) }}</span>
              </v-tooltip>
            </v-item>

            <v-item>
              <v-btn
                @click.native="selectedColor = ''"
                class="mr-2"
                width="30"
                height="30"
                icon
              >
                <v-icon size="30">mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-item>
          </v-row>
        </v-item-group>
      </v-card>

    </template>

    <template v-slot:bottom-actions>
      <v-btn
        @click="confirmModal"
        :disabled="loading"
        color="primary"
        outlined
      >
        {{ $t('reset_custom_icon') }}
      </v-btn>

      <DefaultModal
        @submit="resetCustomization"
        :title="$t('reset_custom_icon')"
        ref="confirmModal"
        submit-button-text="reset"
        deleteModal
      >
        <template v-slot:content>
          <span>{{ $t('reset_custom_icon_message') }}</span>
        </template>
      </DefaultModal>
    </template>
  </DefaultModal>
</template>

<style scoped>
  .drag-over {
    border: 2px dashed var(--v-primary-base);
    border-radius: 4px;
    background-color: var(--v-primary-lighten5);
  }

  .color-card {
    cursor: pointer;
  }
</style>

<script>
  import {mapActions} from 'vuex'

  import AppActions from "@/store/app/actions-types";
  import ContentActions from '@/store/content/actions/actions-types'

  import DefaultModal from '@/components/DefaultModal'
  import UploadField from '@/components/content/default/Form/UploadField'

  export default {
    name: 'CustomIconModal',

    components: {
      DefaultModal,
      UploadField,
    },

    data() {
      return {
        loading: false,
        node: null,
        dropFileDragOver: false,
        selectedColor: null,
        errorMessage: null,
        newImageCover: null,
        colors: [
          { text: 'red', value: '#FA5D54', border: '#A51C14' },
          { text: 'orange', value: '#F7A233', border: '#B36703' },
          { text: 'yellow', value: '#F7CD33', border: '#B38C03' },
          { text: 'green', value: '#4ACE5E', border: '#1A912D' },
          { text: 'blue', value: '#328AF5', border: '#0350B1' },
          { text: 'purple', value: '#B26FD1', border: '#72368E' },
        ],
      }
    },

    computed: {
      cardCover() {
        if (this.newImageCover) {
          return this.newImageCover
        }

        return this.node?.renditions?.[0] ?? null
      }
    },

    methods: {
      ...mapActions("app", [
        AppActions.OPEN_APP_SUCCESS_MESSAGE,
      ]),

      ...mapActions('content/actions', [
        ContentActions.SET_CUSTOM_ICON
      ]),

      open(nodeData) {
        this.newImageCover = null
        this.node = nodeData
        this.selectedColor = nodeData.style_color || nodeData.system_fields?.['style.color'] || null
        this.$refs.modal.submitting = false
        this.$refs.modal.openModal()
      },

      confirmModal() {
        this.$refs.confirmModal.openModal()
      },

      saveCustomization() {
        const formData = new FormData()
        const file = this.$refs.uploadFileField.getFiles()[0]

        formData.append('file', file)
        formData.append('color', this.selectedColor ?? "")

        this.submit(formData)
      },

      resetCustomization() {
        const formData = new FormData()

        formData.append('file', null)
        formData.append('color', "")
        formData.append('reset', true)

        this.submit(formData)
      },

      submit(formData) {
        this.loading = true
        this.$refs.modal.submitting = true

        this[ContentActions.SET_CUSTOM_ICON]({nodeId: this.node.node_id, formData})
          .then(() => {
            this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('set_custom_icon_successful'))

            this.$refs.modal.closeModal()
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message
          })
          .finally(() => {
            this.$refs.uploadFileField.files = []
            this.loading = false
            this.$refs.modal.submitting = false
          })
      },

      loadNewImage(files) {
        let reader = new FileReader()

        reader.onload = (event) => {
          this.newImageCover = event.target.result
        }

        reader.readAsDataURL(files[0])
      },

      clearNewImage() {
        this.newImageCover = null
      },

      dragOver(event) {
        event.preventDefault()
        this.dropFileDragOver = true
      },

      dragEnter(event) {
        this.dragOver(event)
      },

      dragEnd() {
        this.dropFileDragOver = false
      },

      dragLeave() {
        this.dropFileDragOver = false
      },

      onDrop(event) {
        this.dragEnd()

        const files = event.dataTransfer.files

        if (files.length == 0) {
          return
        }

        this.$refs.uploadFileField.setSingleFile(files[0])

        this.loadNewImage(files)
      },
    },
  }
</script>
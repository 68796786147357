var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultModal',{ref:"modal",attrs:{"title":_vm.$t('set_custom_icon'),"loading":_vm.loading,"card-cover":_vm.cardCover,"eager":""},on:{"submit":_vm.saveCustomization,"dropped":_vm.onDrop,"draggedover":function($event){return _vm.dragOver($event)},"draggedenter":function($event){return _vm.dragEnter($event)},"draggedleave":function($event){return _vm.dragLeave()},"draggedend":function($event){return _vm.dragEnd()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"text-h6 black--text"},[_vm._v(_vm._s(_vm.$t('customize_thumbnail')))]),_c('UploadField',{ref:"uploadFileField",class:{ 'drag-over': _vm.dropFileDragOver },attrs:{"errorMessage":_vm.errorMessage,"multiple":false,"accept":'image/*'},on:{"clearInput":_vm.clearNewImage},nativeOn:{"change":function($event){return _vm.loadNewImage($event.target.files)}}}),_c('p',{staticClass:"text-h6 black--text"},[_vm._v(_vm._s(_vm.$t('set_icon_color')))]),_c('v-card',{staticClass:"d-flex justify-start my-5 ml-5",attrs:{"flat":"","tile":""}},[_c('v-item-group',{model:{value:(_vm.selectedColor),callback:function ($$v) {_vm.selectedColor=$$v},expression:"selectedColor"}},[_c('v-row',[_vm._l((_vm.colors),function(color){return _c('v-item',{key:color.value,attrs:{"value":color.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mr-2 rounded-circle color-card",style:({
                    backgroundColor: color.value,
                    border: '2px solid',
                    borderColor: active ? color.border : 'white'
                  }),attrs:{"width":"30","height":"30"},nativeOn:{"click":function($event){return toggle($event)}}},'v-card',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(color.text)))])])]}}],null,true)})}),_c('v-item',[_c('v-btn',{staticClass:"mr-2",attrs:{"width":"30","height":"30","icon":""},nativeOn:{"click":function($event){_vm.selectedColor = ''}}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-close-circle-outline")])],1)],1)],2)],1)],1)]},proxy:true},{key:"bottom-actions",fn:function(){return [_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"primary","outlined":""},on:{"click":_vm.confirmModal}},[_vm._v(" "+_vm._s(_vm.$t('reset_custom_icon'))+" ")]),_c('DefaultModal',{ref:"confirmModal",attrs:{"title":_vm.$t('reset_custom_icon'),"submit-button-text":"reset","deleteModal":""},on:{"submit":_vm.resetCustomization},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('reset_custom_icon_message')))])]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  getIconByMimetype(extension, mimeType) {
    const icons = this.icons()

    for (var mime in icons) {
      const regex = new RegExp(`\\b${mime}\\b`, "i")

      if (regex.exec(extension || "") || regex.exec(mimeType || "")) {
        return icons[mime]
      }
    }

    return icons['default/file']
  },

  icons: () => {
    return {
      "(text/plain|text/rtf|txt)": {
        "icon": "mdi-text-box",
        "color": "#4285F4"
      },
      "jsm": {
        "icon": "mdi-creation-outline",
        "color": "primary"
      },
      "jst": {
        "icon": "mdi-image-multiple-outline",
        "color": "#129EAF"
      },
      "glb": {
        "icon": "mdi-cube-scan",
        "color": "gray"
      },
      "(psd|pdd|psdt|psb|pdp)": {
        "icon": "$adobe_photoshop"
      },
      "(ai|ait)": {
        "icon": "$adobe_illustrator"
      },
      "(indp|idap|indd|indl|indt|indb|inx|idml)": {
        "icon": "$adobe_indesign"
      },
      "(raw|rw2|dng)": {
        "icon": "$adobe_lightroom"
      },
      "(aep|aet|aepx)": {
        "icon": "$adobe_after_effects"
      },
      "(prproj|prel)": {
        "icon": "$adobe_premiere"
      },
      "(fla|swf|swc|jsfl|xfl)": {
        "icon": "$adobe_animate"
      },
      "xd": {
        "icon": "$adobe_xd"
      },
      "dwt": {
        "icon": "$adobe_dreamweaver"
      },
      "key": {
        "icon": "$keynote"
      },
      "mxf": {
        "icon": "mdi-movie",
        "color": "#D93025"
      },
      "default/file": {
        "icon": "mdi-file",
        "color": "#D93025"
      },
      "image": {
        "icon": "mdi-image",
        "color": "#D93025"
      },
      "audio": {
        "icon": "mdi-volume-high",
        "color": "#D93025"
      },
      "video": {
        "icon": "mdi-movie",
        "color": "#D93025"
      },
      "application/pdf": {
        "icon": "mdi-file-pdf-box",
        "color": "#D93025"
      },
      "application/msword": {
        "icon": "$office_word",
        "color": "#4285F4"
      },
      "application/vnd.ms-word": {
        "icon": "$office_word",
        "color": "#4285F4"
      },
      "application/vnd.oasis.opendocument.text": {
        "icon": "$office_word",
        "color": "#4285F4"
      },
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
        "icon": "$office_word",
        "color": "#4285F4"
      },
      "application/vnd.google-apps.document": {
        "icon": "mdi-text-box",
        "color": "#4285F4"
      },
      "application/vnd.ms-excel": {
        "icon": "$office_excel",
        "color": "#0F9D58"
      },
      "application/vnd.openxmlformats-officedocument.spreadsheetml": {
        "icon": "$office_excel",
        "color": "#0F9D58"
      },
      "application/vnd.oasis.opendocument.spreadsheet": {
        "icon": "$office_excel",
        "color": "#0F9D58"
      },
      "application/vnd.google-apps.spreadsheet": {
        "icon": "mdi-google-spreadsheet",
        "color": "#0F9D58"
      },
      "application/vnd.ms-powerpoint": {
        "icon": "$office_power_point",
        "color": "#D93025"
      },
      "application/vnd.openxmlformats-officedocument.presentationml": {
        "icon": "$office_power_point",
        "color": "#D93025"
      },
      "application/vnd.oasis.opendocument.presentation": {
        "icon": "$office_power_point",
        "color": "#D93025"
      },
      "application/vnd.google-apps.presentation": {
        "icon": "mdi-file-presentation-box",
        "color": "#F4B400"
      },
      "application/vnd.google.colaboratory": {
        "icon": "mdi-language-python",
        "color": "#FFD43B"
      },
      "text/html": {
        "icon": "mdi-language-html5",
        "color": "#E44D26"
      },
      "application/json": {
        "icon": "mdi-code-json",
        "color": "#FBC02D"
      },
      "application/gzip": {
        "icon": "mdi-zip-box",
        "color": "#AFB42B"
      },
      "application/zip": {
        "icon": "mdi-zip-box",
        "color": "#AFB42B"
      },
    }
  }
}

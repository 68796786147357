<template>
  <DefaultModal
    ref="grant_access_modal"
    @submit="submitGrants"
    :hide-save-button="step == 1"
  >
    <template v-slot:title>
      <v-btn
        v-if="step == 2"
        @click="goBack"
        icon
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <span class="title-text left-align">
        <span class="headline">{{ $t('grant_access') }}</span>
        <span class="font-italic normal"> - {{ node.name }}</span>
      </span>
    </template>

    <template v-slot:content>
      <UserMultiSelect
        v-model="selectedUsersAndGroups"
        @selected-change="userOrGroupSelected"
        :items="normalizedUsersAndGroups"
        :loading="loadingUsersGroupsList"
        :rules="[v => (v && v.length > 0) || $t('form_rules.mandatory')]"
        label="add_users_and_groups"
        item-value="id"
        class="left-align"
      />
      <v-stepper
        v-model="step"
        class="custom-stepper left-align"
        outlined
        flat
      >
        <v-stepper-items>
          <v-stepper-content
            step="1"
            class="custom-stepper-content"
          >
            <v-list v-if="!loadingGrantsList">
              <span class="name-text list-name">{{ `${$t('local')}` }}</span>
              <v-list-item
                v-for="grant in nodeGrants"
                :key="grant.node_grant_id"
                class="clear-left"
              >
                <v-list-item-avatar class="list-avatar" :class="grant.group_id ? 'group-bg' : 'avatar'">
                  <template v-if="grant.user_id">
                    <img
                      v-if="getUserAvatarUrl(grant.user_id)"
                      :src="getUserAvatarUrl(grant.user_id)"
                      :alt="`${grant.user_id} avatar`"
                    >

                    <span v-else class="secondary--text headline text-uppercase font-weight-regular">{{ getUserName(grant.user_id)[0] }}</span>
                  </template>

                  <v-icon v-else
                    color="secondary"
                  >mdi-account-multiple</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="title-text">
                    <span class="name-text">{{ grant.user_id ? getUserName(grant.user_id) : getGroupTitle(grant.group_id) }}</span>
                    <p><span v-if="grant.recursive" class="from-text grant-info">{{`${$t('recursive')}`}}</span>
                    <span v-else class="from-text grant-info">{{`${$t('not_recursive')}`}}</span></p>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-list-item-subtitle>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="text--primary"
                        >{{ getUserName(grant.provider) }}</span>
                      </template>
                      <span>{{ $t('grant_provider', {provider: getUserName(grant.provider)}) }}</span>
                    </v-tooltip>

                    <p><span class="">{{ formatDate(grant.created_at) }}</span></p>
                  </v-list-item-subtitle>

                  <v-btn
                    icon
                    color="grey darken-2"
                    @click="removeGrantModal(grant)"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

            <v-list v-if="!loadingGrantsParentsList">
              <span class="name-text list-name">{{ `${$t('inherited')}` }}</span>
              <v-list-item
                v-for="grant in parentsGrants"
                :key="grant.node_grant_id"
                class="clear-left"
              >
                <v-list-item-avatar class="list-avatar" :class="grant.group_id ? 'group-bg' : 'avatar'">
                  <template v-if="grant.user_id">
                    <img
                      v-if="getUserAvatarUrl(grant.user_id)"
                      :src="getUserAvatarUrl(grant.user_id)"
                      :alt="`${grant.user_id} avatar`"
                    >

                    <span v-else class="secondary--text headline text-uppercase font-weight-regular">{{ getUserName(grant.user_id)[0] }}</span>
                  </template>

                  <v-icon v-else
                    color="secondary"
                  >mdi-account-multiple</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="title-text">
                    <span class="name-text">{{ grant.user_id ? getUserName(grant.user_id) : getGroupTitle(grant.group_id) }}</span>
                    <span v-if="grant.recursive" class="font-italic normal">{{` - (${$t('recursive')})` }}</span>
                    <p><span class="from-text grant-info">{{` ${$t('from')}` }}:</span> <a class="grant-info" @click="navigateTo(grant)">{{grant.path}}</a></p>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-list-item-subtitle>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="text--primary"
                        >{{ getUserName(grant.provider) }}</span>
                      </template>

                      <span>{{ $t('grant_provider', {provider: getUserName(grant.provider)}) }}</span>
                    </v-tooltip>

                    <p><span>{{ formatDate(grant.created_at) }}</span></p>
                  </v-list-item-subtitle>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

          </v-stepper-content>

          <v-stepper-content
            step="2"
            class="custom-stepper-content"
          >
            <v-checkbox
              v-model="isRecursive"
              :label="$t('recursive_access')"
            ></v-checkbox>

            <v-checkbox
              v-model="sendEmail"
              :label="$t('send_email')"
            ></v-checkbox>

            <v-textarea
              v-model="emailMessage"
              :label="$t('message')"
              no-resize
              outlined
            ></v-textarea>
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>

      <DefaultModal
        :title="$t('remove_grant')"
        @submit="removeGrant"
        ref="remove_grant_confirm_modal"
        submit-button-text="confirm"
        deleteModal
      >
        <template v-slot:content>
          <span>{{ $t('grant_removal_confirmation') }}</span>
          <span class="font-weight-bold"> {{ selectedGrant.user_id ? getUserName(selectedGrant.user_id): getGroupTitle(selectedGrant.group_id) }}</span>?
        </template>
      </DefaultModal>

    </template>
  </DefaultModal>
</template>

<style scoped>
  .clear-left {
    padding: 0;
  }

  .left-align {
    margin: 0 16px;
  }

  .title-text {
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .name-text {
    text-align: left;
    font-family: Roboto;
    letter-spacing: 0.38px;
    font-weight: 500;
    color: #202124;
  }

  .list-name {
    font-family: Roboto;
    letter-spacing: 0.38px;
    color: #3C4043;
  }

  .grant-info {
    text-align: left;
    letter-spacing: -0.22px;
  }

  .from-text {
    color: #5F6368;
  }

  .custom-stepper {
    box-shadow: none;
  }

  .custom-stepper-content {
    padding: 0;
    height: 350px;
    overflow: auto;
  }

  .avatar {
    background-color: var(--v-primary-base) !important;
  }

  .group-bg {
    background-color: var(--v-primary-base) !important;
  }

  .list-avatar {
    justify-content: center;
    margin-bottom: 23px;
  }
</style>

<script>
import { mapActions } from 'vuex'

import DefaultModal from '@/components/DefaultModal'
import router from '@/router'
import UserMultiSelect from '@/components/core/UserMultiSelect'
import AppActions from '@/store/app/actions-types'
import UserActions from '@/store/core/user/actions-types'
import GroupActions from '@/store/core/group/actions-types'
import GrantActions from '@/store/node/grant/actions-types'
import NotificationActions from '@/store/core/notification/actions-types'
import HumanReadable from "@/utils/human_readable"

export default {
  name: 'GrantAccessModal',

  components: {
    DefaultModal,
    UserMultiSelect,
  },

  data: () => ({
    loadingUsersGroupsList: false,
    loadingGrantsList: false,
    loadingGrantsParentsList: false,
    selectedUsersAndGroups: [],
    selectedGrant: {},
    usersAndGroups: [],
    nodeGrants: [],
    parentsGrants: [],
    isRecursive: true,
    sendEmail: true,
    emailMessage: null,
    node: {},
    step: 1,
  }),

  computed: {
    normalizedUsersAndGroups() {
      return this.usersAndGroups.reduce((result, el) => {
        if (el.users_id) {
          if (this.nodeGrants.find(ng => ng.user_id == el.users_id))
            return result
        }

        if (el.user_group_id) {
          if (this.nodeGrants.find(ng => ng.group_id == el.user_group_id))
            return result
        }

        result.push({
          id: el.users_id ? `users-${el.users_id}` : `group-${el.user_group_id}`,
          idRaw: el.users_id || el.user_group_id,
          name: el.name || el.group_title,
          isUser: el.users_id ? true : false,
          isGroup: el.user_group_id ? true : false,
          email: el.email ? el.email : null
        })

        return result
      }, [])
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
    ]),

    ...mapActions('core/user', [
      UserActions.GET_ALL_USERS,
    ]),

    ...mapActions('core/group', [
      GroupActions.GET_ALL_GROUPS,
    ]),

    ...mapActions('node/grant', [
      GrantActions.ADD_GRANT,
      GrantActions.DELETE_GRANT,
      GrantActions.GET_GRANTS_BY_NODE_WITH_PARENTS,
    ]),

    ...mapActions('core/notification', [
      NotificationActions.SEND_MESSAGE,
    ]),

    fetchData() {
      this.getUsersAndGroups()
      this.getGrantsList()
    },
    getGrantsList() {
      this.loadingGrantsList = true
      this.loadingGrantsParentsList = true
      this.nodeGrants = [...[]]

      this[GrantActions.GET_GRANTS_BY_NODE_WITH_PARENTS](this.node.node_id)
        .then((result) => {
          if (result.parents != undefined) {
            this.parentsGrants = [...result.parents]
            this.loadingGrantsParentsList = false
          }
          if (result.current != undefined) {
            this.nodeGrants = [...result.current]
            this.loadingGrantsList = false
          }
        })
    },

    getUsersAndGroups() {
      this.loadingUsersGroupsList = true
      this.selectedUsersAndGroups = [...[]]
      this.usersAndGroups = [...[]]

      const usersOptions = {
        'filter[tenant_id]': this.node.tenant_id,
        'filter[include_foreign]': true,
        'filter[active]': true,
        'limit': 9999,
      }

      const groupsOptions = {
        'filter[tenant]': this.node.tenant_id,
        'filter[include_foreign]': true,
        'limit': 9999,
      }

      const usersPromise = this[UserActions.GET_ALL_USERS](usersOptions)
      usersPromise.then((result) => {
        this.usersAndGroups = [...this.usersAndGroups.concat(result.items)]
      })

      const groupsPromise = this[GroupActions.GET_ALL_GROUPS](groupsOptions)
      groupsPromise.then((result) => {
        this.usersAndGroups = [...this.usersAndGroups.concat(result.items)]
      })

      Promise.all([usersPromise, groupsPromise])
        .finally(() => this.loadingUsersGroupsList = false)
    },

    submitGrants() {
      let options = []
      let usersIds = []
      let groupsIds = []

      this.selectedUsersAndGroups.forEach(id => {
        const item = this.normalizedUsersAndGroups.find(el => el.id == id)

        options.push({
          node_id: this.node.node_id,
          group_id: item.isGroup ? item.idRaw : null,
          user_id: item.isUser ? item.idRaw : null,
          recursive: this.isRecursive,
        })

        if (item.isUser) {
          usersIds.push(item.idRaw)
        }

        if (item.isGroup) {
          groupsIds.push(item.idRaw)
        }
      })

      this[GrantActions.ADD_GRANT](options)
        .then(() => {
          this.selectedUsersAndGroups = [...[]]

          this.sendNotification(usersIds, groupsIds)
          this.getGrantsList()
        })
        .finally(() => {
          this.$refs.grant_access_modal.submitting = false

          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('grant_added_successfully'))
        })
    },

    sendNotification(usersIds = [], groupsIds = []) {
      if (! this.sendEmail) {
        return
      }

      const options = {
        subject: `${this.$t('grant_access_notification')}: ${this.node.name}`,
        content: this.emailMessage.replace(/\n/g,"\n<br>"),
        users_ids: usersIds,
        groups_ids: groupsIds,
      }

      this[NotificationActions.SEND_MESSAGE](options)

      this.clearForm()
    },

    removeGrantModal(grant) {
      this.selectedGrant = {...grant}

      this.$refs.remove_grant_confirm_modal.openModal()
    },

    removeGrant() {
      this[GrantActions.DELETE_GRANT](this.selectedGrant.node_grant_id)
        .then(() => {
          const index = this.nodeGrants.map((ng) => {return ng.node_grant_id}).indexOf(this.selectedGrant.node_grant_id)

          if (index >= 0) this.nodeGrants.splice(index, 1)

          this.selectedGrant = {...{}}

          this.$refs.remove_grant_confirm_modal.closeModal()
        })
    },

    navigateTo(grant) {
      const routeData = router.resolve({name: 'ContentNavigation', params: { appId: this.$route.params.appId, parentId: grant.node_id }});
      window.open(routeData.href, '_blank');
    },

    getUserAvatarUrl(userId) {
      return (this.usersAndGroups.find(el => el.users_id == userId)?.avatar_url) ?? null
    },

    getUserName(userId) {
      return (this.usersAndGroups.find(el => el.users_id == userId)?.name) ?? `${this.$t('user')} ID: ${userId}`
    },

    getGroupTitle(groupId) {
      return (this.usersAndGroups.find(el => el.user_group_id == groupId)?.group_title) ?? `${this.$t('group')} ID: ${groupId}`
    },

    goBack() {
      this.step = 1

      this.clearForm()
    },

    userOrGroupSelected() {
      this.step = 2

      this.emailMessage = this.$t('grant_email_message', {nodeName: this.node.name, fullPath: this.getGrantedNodeAccessUrl()})
    },

    getGrantedNodeAccessUrl() {
      const parentId = this.node.type == "folder" ? this.node.node_id : this.node.node_parent

      const route = router.resolve({
        name: 'ContentNavigation',
        params: { appId: this.$route.params.appId, parentId }
      })

      if (route.href == "/") {
        return this.$el.baseURI
      }

      return new URL(route.href, window.location.origin).href
    },

    formatDate(dateStr) {
      if (!dateStr) {
        return "-";
      }

      return HumanReadable.dateTime(dateStr);
    },

    clearForm() {
      this.selectedUsersAndGroups = [...[]]
      this.sendEmail = false
      this.isRecursive = true
      this.emailMessage = null
    },

    open(node) {
      this.node = {...node}
      this.step = 1

      this.clearForm()
      this.sendEmail = true

      this.$refs.grant_access_modal.openModal()

      this.fetchData()
    },

    close() {
      this.$refs.grant_access_modal.closeModal()
    },
  },
}
</script>

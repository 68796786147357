<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        link
        @dblclick="$emit('click-folder', data)"
        :outlined="outlined"
        :color="hover ? 'grey lighten-3' : 'white'"
      >
        <v-img
          class="white--text align-center custom-thumb"
          height="200px"
          v-if="dense == false && thumbUrl"
          :src="thumbUrl"
          align="center"
          justify="center"
          contain
        ></v-img>

        <v-img
          v-else-if="dense == false"
          class="white--text align-center custom-thumb"
          height="200px"
          align="center"
          justify="center"
          @click="$emit('click-folder', data)"
        >
          <v-icon
            :color="folderColor"
            class="thumb-icon"
          >{{ data.only_path ? 'mdi-folder-outline' : 'mdi-folder' }}</v-icon>
        </v-img>

        <v-card-actions class="card-actions">
          <v-icon
            @click="toggle"
            :color="folderColor"
          >{{ data.only_path ? 'mdi-folder-outline' : checked ? 'mdi-folder-check' : 'mdi-folder' }}</v-icon>

          <v-tooltip open-delay="1000" bottom>
            <template v-slot:activator="{ on, attrs }">

              <v-card-subtitle
                v-bind="attrs"
                v-on="on"
                class="custom-subtitle prevent-select"
              >{{ data.name }}</v-card-subtitle>

            </template>
            <span>{{ data.name }}</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-menu
            v-if="!data.only_path"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <ContentActionsPopup
              :data="data"
              :filter-type="folderNodeType()"
              @move-success="$emit('move-success')"
            />
          </v-menu>

        </v-card-actions>
      </v-card>
    </template>
  </v-hover>
</template>

<style scoped>
  .custom-thumb {
    cursor: pointer;
    background-color: #DADCE0;
  }

  .thumb-icon {
    font-size: 150px;
  }

  .card-actions {
    padding: 1px 8px 1px 8px;
  }

  .custom-subtitle {
    font-weight: 500;
    color: rgb(60, 64, 67) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 16px 12px !important;
  }

  .v-card {
    border-color: #DADCE0 !important;
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .selected-folder {
    fill: var(--v-primary-base)
  }

  .drag-over {
    border: 2px dashed;
    border-radius: 4px;
    border-color: var(--v-primary-base);
  }
</style>

<script>

import NodeTypes from '@/store/content/actions/node-types.js'

export default {
  name: 'FolderCard',

  beforeCreate: function () {
    this.$options.components.ContentActionsPopup = require('@/components/content/ContentActionsPopup.vue').default
  },

  data: () => ({
    checked: false,
  }),
  props: {
    data: {
      type: Object
    },

    dense: {
      type: Boolean,
      default: false,
    },

    outlined: {
      type: Boolean,
      default: true,
    },
  },

  computed : {
    folderColor() {
      if (this.checked) {
        return 'primary'
      }

      if (this.data.only_path || ! this.data.style_color) {
        return 'defaultIconColor'
      }

      return this.data.style_color
    },

    thumbUrl() {
      if (Array.isArray(this.data.renditions)) {
        return this.data.renditions[0]
      }

      if (typeof this.data.renditions === 'object') {
        return Object.values(this.data.renditions)[0]
      }

      return false;
    }
  },

  methods: {
    folderNodeType() {
      return NodeTypes.NODE_FOLDER
    },

    toggle() {
      if (! this.data.only_path) {
        this.checked = !this.checked
        this.$emit('toggle-selection', this.data.node_id)
      }
    },
  }
}

</script>

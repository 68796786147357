var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"link":"","outlined":_vm.outlined,"color":hover ? 'grey lighten-3' : 'white'},on:{"dblclick":function($event){return _vm.$emit('click-folder', _vm.data)}}},[(_vm.dense == false && _vm.thumbUrl)?_c('v-img',{staticClass:"white--text align-center custom-thumb",attrs:{"height":"200px","src":_vm.thumbUrl,"align":"center","justify":"center","contain":""}}):(_vm.dense == false)?_c('v-img',{staticClass:"white--text align-center custom-thumb",attrs:{"height":"200px","align":"center","justify":"center"},on:{"click":function($event){return _vm.$emit('click-folder', _vm.data)}}},[_c('v-icon',{staticClass:"thumb-icon",attrs:{"color":_vm.folderColor}},[_vm._v(_vm._s(_vm.data.only_path ? 'mdi-folder-outline' : 'mdi-folder'))])],1):_vm._e(),_c('v-card-actions',{staticClass:"card-actions"},[_c('v-icon',{attrs:{"color":_vm.folderColor},on:{"click":_vm.toggle}},[_vm._v(_vm._s(_vm.data.only_path ? 'mdi-folder-outline' : _vm.checked ? 'mdi-folder-check' : 'mdi-folder'))]),_c('v-tooltip',{attrs:{"open-delay":"1000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card-subtitle',_vm._g(_vm._b({staticClass:"custom-subtitle prevent-select"},'v-card-subtitle',attrs,false),on),[_vm._v(_vm._s(_vm.data.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.data.name))])]),_c('v-spacer'),(!_vm.data.only_path)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('ContentActionsPopup',{attrs:{"data":_vm.data,"filter-type":_vm.folderNodeType()},on:{"move-success":function($event){return _vm.$emit('move-success')}}})],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }